// middleware to inject our auth key into RSAA actions
// so we don't have to do it manually each time
import { RSAA } from 'redux-api-middleware';
import Cookies from 'js-cookie';
import { TOKEN, selectToken } from '../user';

export default (store) => (next) => (action) => {
  const callApiAction = action[RSAA];
  let token = '';

  if (callApiAction) {
    token = Cookies.get(TOKEN) || ''; // client side token
    if (token === '') {
      const state = store.getState(); // server side token from cookie
      token = selectToken(state);
    }
  }

  if (callApiAction && token !== '') {
    callApiAction.headers = Object.assign({}, callApiAction.headers, {
      Authorization: token,
      'User-Agent': 'Web/0.1.0 01',
    });
  }
  return next(action);
};
