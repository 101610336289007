import App from 'next/app';
import React from 'react';
import { default as NextError } from 'next/error';
import withRedux from 'next-redux-wrapper';
import configureStore from '../app/configureStore';
import { Provider } from 'react-redux';
import { compose, Store } from 'redux';
import { IntlProvider } from 'react-intl';
import Router from 'next/router';
import messages from '../app/i18n/en';
import HiddenAudioPlayerContainer from '../app/player/audio/HiddenAudioPlayerContainer';
import NProgress from 'nprogress';
import { AudioPlayerContext } from '../app/player';
import withChannelName from '../app/withChannelName';
import { doSetTokenFromRequest, doSetCookieConsent } from '../app/user';
import { extractConsentCookieFromRequest } from '../app/user/components/CookieConsent';
import { doFetchUser } from '../app/user';
import { doFetchChannelByUniqueName } from '../app/channel/channel.actions';
import { doFetchBundlePlans } from '../app/subscribe/subscribe.actions';
import '../app/layout/themes/tailwind.css';
import '@reach/menu-button/styles.css';

const SP_STATE = 'SP_STATE';

// set up nprogress
Router.events.on('routeChangeStart', (url) => {
  console.log(`Loading: ${url}`);
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

interface InitialProps {
  statusCode: number;
}

interface Props {
  store: Store;
}

interface State {
  audioPosition: number;
  audioDuration: number;
}

class SupaPassApp extends App<Props, null, State> {
  static async getInitialProps({ Component, ctx }) {
    let pageProps: InitialProps = {
      statusCode: 200,
    };

    // if we have a cookie, fetch the token and stick it in the store
    // that way we can use it in our API middleware for authenticating
    // requests on the server.
    if (ctx.req) {
      ctx.store.dispatch(doSetTokenFromRequest(ctx.req));
    }

    // handle consent cookie for analytics and privacy policy,
    // update the store with cookie keys
    const consentCookie = extractConsentCookieFromRequest(ctx.req);
    Object.keys(consentCookie).forEach((key) => {
      ctx.store.dispatch(doSetCookieConsent(key, consentCookie[key]));
    });

    [, , , pageProps] = await Promise.all([
      await ctx.store.dispatch(doFetchUser()),
      ctx.store.dispatch(doFetchChannelByUniqueName(ctx.channelName)),
      ctx.store.dispatch(doFetchBundlePlans(ctx.channelName)),
      Component.getInitialProps
        ? (await Component.getInitialProps(ctx)) || {}
        : {},
    ]);

    if (pageProps.statusCode >= 400 && pageProps.statusCode <= 503) {
      if (ctx.res) {
        ctx.res.statusCode = pageProps.statusCode;
      }
    }

    return { pageProps };
  }

  constructor(props) {
    super(props);
    this.state = { audioPosition: 0, audioDuration: 0 };
  }

  // TODO move player state out of _app.js
  updateAudioPosition = (position: number, duration: number) => {
    this.setState({
      audioPosition: position,
      audioDuration: duration,
    });
  };

  componentDidMount() {
    const { store } = this.props;

    // let Cypress know about the store when in test
    if (typeof window !== 'undefined' && window.Cypress) {
      window.__store__ = store;
    }

    if (typeof window !== 'undefined') {
      // we're on client, see if we have local theme and user
      // state to restore
      const localState = JSON.parse(localStorage.getItem(SP_STATE)) || {};

      if (localState.user && localState.user.isAuthed) {
        store.dispatch({ type: 'SET_USER', payload: localState.user });
        store.dispatch({
          type: 'SET_THEME',
          payload: localState.layout.activeTheme,
        });
      }

      // subscribe to store updates and save local state slice
      store.subscribe(() => {
        const state = store.getState();
        const slice = selectSavedState(state);
        localStorage.setItem(SP_STATE, JSON.stringify(slice));
      });
    }
  }

  render() {
    const { Component, pageProps, store } = this.props;

    if (pageProps.statusCode >= 400 && pageProps.statusCode <= 503) {
      return (
        <NextError
          statusCode={pageProps.statusCode}
          title={pageProps.statusMessage}
        />
      );
    }

    return (
      <Provider store={store}>
        <IntlProvider locale="en" messages={messages}>
          <AudioPlayerContext.Provider
            value={{
              position: this.state.audioPosition,
              duration: this.state.audioDuration,
            }}>
            <>
              <Component {...pageProps} />
              <HiddenAudioPlayerContainer
                onTimeUpdate={this.updateAudioPosition}
              />
            </>
          </AudioPlayerContext.Provider>
        </IntlProvider>
      </Provider>
    );
  }
}

const selectSavedState = (state) => {
  return {
    user: state.user,
    layout: {
      activeTheme: state.layout.activeTheme,
    },
    passes: state.passes,
  };
};

export default compose(withRedux(configureStore), withChannelName)(SupaPassApp);
