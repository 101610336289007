export default {
  'app.audio.link': 'Audio',
  'app.audio.title': 'Audio | {name}',
  'app.account.link': 'Account',
  'app.social.link': 'Social',
  'app.channel.audio.empty': 'There are no releases on this channel.',
  'app.channel.videos.empty': 'There are no videos for this channel.',
  'app.channel.button.subscribe': 'Subscribe',
  'app.discover.featured': 'Featured',
  'app.discover.normal': 'Discover More',
  'app.discover.title': 'Discover',
  'app.greeting_message': "Hello {name}, how's it going?",
  'app.info.link': 'About',
  'app.nav.to.audio': 'All releases',
  'app.nav.to.video': 'All videos',
  'app.nav.to.feed': 'View feed',
  'app.overflow.about': 'About',
  'app.overflow.support': 'Support',
  'app.overflow.terms': 'Terms',
  'app.user.changeTheme': 'Change theme',
  'app.user.login': 'Login',
  'app.user.logout': 'Sign out',
  'app.user.profile': 'Profile',
  'app.user.register': 'Register',
  'app.user.changepassword': 'Change password',
  'app.validation.emailRequired': 'We need at least an email address.',
  'app.video.link': 'Video',
  'app.video.title': 'Videos | {name}',
  'app.video.error':
    'There was a probem loading the video. Please try refreshing the page.',
  'app.welcome': 'Welcome {name}!',
  'app.meta.audio.title': '{channel} Audio',
  'app.meta.audio.description': 'Stream audio from {channel}.',
  'app.meta.audio.release.title': '{release} | {channel}',
  'app.meta.audio.release.description': 'Stream {release} on {channel}',
  'app.meta.audio.track.title': '{trackTitle} on {channel}',
  'app.meta.audio.track.description': 'Listen to {trackTitle} on {channel}',
  'app.meta.video.title': '{channel} Video',
  'app.meta.video.description': 'Stream videos from {channel}.',
  'app.meta.video.single.title': '{video} | {channel}',
  'app.meta.video.single.description': 'Watch {video} on {channel}',
  'app.meta.collection.single.title': '{collection} | {channel}',
  'app.meta.collection.single.description': '{collection} | {channel}',
  'app.meta.about.title': '{channel}',
  'app.meta.about.description': 'Subscribe to {channel}.',
  'app.meta.articles.title': 'Articles | {channel}',
  'app.meta.articles.description': 'Articles on {channel}',
  'app.meta.article.single.title': '{article} | {channel}',
  'app.meta.article.single.description': '{article} on {channel}',
  'app.meta.social.title': '{channel} Social buzz',
  'app.meta.social.description': 'Social media updates from {channel}.',
  'app.meta.support.title': 'Tech support',
  'app.meta.support.description': 'Tech support',
  'app.meta.subscriptions.title': 'Subscriptions',
  'app.meta.subscriptions.description': 'Choose your subscription plan.',
  'app.meta.passwordreset.title': 'Password reset',
  'app.meta.passwordreset.description': 'Reset your password',
  'app.passwordchange.button': 'Change your password',
  'app.meta.settings.subscriptions.title': 'Subscriptions',
  'app.meta.settings.subscriptions.description':
    'Manage your active subscriptions',
  'app.meta.settings.profile.title': 'Profile',
  'app.meta.settings.profile.description': 'Edit your profile.',
  'app.meta.settings.license.title': 'Terms and privacy',
  'app.meta.settings.license.description':
    'Review the terms and privacy policy',
  'app.meta.faqs.title': 'Frequently Asked Questions',
  'app.meta.faqs.description': 'Answers to commonly asked questions.',
  'app.subscribe.link': 'Subscribe',
  'app.subscribe.unsubscribe.description':
    "Unsubscribe at any time. Send us an email and we'll cancel your subscription within 2 working days.",
  'app.user.viewsubscriptions': 'View subscriptions',
  'app.checkout.label.paynow': 'Pay Now',
  'app.checkout.label.changecard': 'Change card',
  'app.checkout.label.dontchange': "Don't change",
  'app.checkout.label.addcard': 'Add card',
};
