import { createStore, applyMiddleware, compose } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import * as Sentry from '@sentry/node';
import { RewriteFrames } from '@sentry/integrations';
import getConfig from 'next/config';
import createSentryMiddleware from 'redux-sentry-middleware';
import rootReducer from './rootReducer';
import apiAuthInjectorMiddleware from './api/APIAuthInjectorMiddleware';
import apiBaseUrlInjectorMiddleware from './api/APIBaseUrlInjectorMiddleware';
import apiTokenReceiverMiddleware from './api/APITokenReceiverMiddleware';

// array of middlewares for the store
const middlewares = [
  apiBaseUrlInjectorMiddleware,
  apiAuthInjectorMiddleware,
  apiMiddleware,
  apiTokenReceiverMiddleware,
];

// middlewares to use outside production
if (process.env.NODE_ENV !== 'production') {
  middlewares.push(require('redux-immutable-state-invariant').default());
}

// Production crash reporting
if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  console.log('DSN var', process.env.NEXT_PUBLIC_SENTRY_DSN);
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: true, // process.env.NODE_ENV === "production",
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, 'app:///_next');
          return frame;
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    // dsn:
    //   'https://39c0c2cd32f349cb810f9c9015d48f8f@o128957.ingest.sentry.io/5381519',
  });

  const sentryMiddleware = createSentryMiddleware(Sentry);
  middlewares.push(sentryMiddleware);
}

// if we're in-browser and have redux dev tools installed, enable time travel
// or just use redux compose
const composeEnhancers =
  typeof window !== 'undefined'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

export default (preloadedState) => {
  return createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
};
