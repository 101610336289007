import createEntityReducer from '../createEntityReducer';
import {
  REQ_RELEASES,
  RES_RELEASES_SUCCESS,
  RES_RELEASES_FAIL,
  RES_RELEASE,
  REQ_RELEASE,
  RES_RELEASE_FAIL,
} from '../channel';
import Release from './Release';

export const ReleaseEntityReducer = createEntityReducer(
  { key: 'albums', id: 'albumId' },
  [REQ_RELEASES, RES_RELEASES_SUCCESS, RES_RELEASES_FAIL],
  [REQ_RELEASE, RES_RELEASE, RES_RELEASE_FAIL]
);

export const sortReleasesByReleaseDate = (
  releases: Array<Release>
): Array<Release> =>
  releases.sort((a, b) => {
    return b.year - a.year || a.albumId - b.albumId;
  });
