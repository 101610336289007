import { RES_CHANNEL_SUCCESS } from '../channel';

import { AppState } from '../common/types/AppState';
import { ChannelAction } from './channel.types';

export default function reducer(state: Object = {}, action: ChannelAction) {
  switch (action.type) {
    case RES_CHANNEL_SUCCESS: {
      const channel = action.payload;
      const homepage = filterHomepageObjectIncorrectness(channel.homepage);
      let channelsHolder = {};
      channelsHolder[channel.artistId] = { ...channel, homepage };
      return Object.assign({}, state, channelsHolder);
    }
    default:
      return state;
  }
}

/**
 * If you ever entered three menuLinks there will forever be three
 * menuLinks. If you delete one the array will still contain three
 * objects, but the object's values will be set to null.
 *
 * Here we filter them out because gaaaahd damn it Drupal.
 */
function filterHomepageObjectIncorrectness(page) {
  if (!page) return { menuLinks: [], footerLinks: [], testimonials: [] };
  const menuLinks = page.menuLinks
    ? page.menuLinks.filter((link) => link.url !== null)
    : [];
  const footerLinks = page.footerLinks
    ? page.footerLinks.filter((link) => link.url !== null)
    : [];
  const testimonials = page.testimonials
    ? page.testimonials.filter((quote) => quote.quote !== null)
    : [];
  return { ...page, menuLinks, footerLinks, testimonials };
}

export const selectChannelsByIds = (
  state: AppState,
  channelIds: Array<number>
) => {
  let channels = [];
  channelIds.map((channelId) =>
    channels.push(state.entities.channels[channelId])
  );
  return channels;
};
