/**
 * @flow
 *
 * Redux middleware to catch the token from a successful login
 * attempt and save it to a cookie
 */
import Cookies from 'js-cookie';
import Router from 'next/router';
import { TOKEN, RES_AUTH_SUCCESS, REQ_AUTH_LOGOUT, doLogout } from '../user';
import { RES_USER_FAIL } from '../user/user.constants';
import { selectIsAuthed } from '../user/user.selectors';

const cookieOptions = {
  // maxAge: 86400 * 1500 // 1500 days in seconds
  expires: 1000,
};

export default (store) => (next: Function) => (action: {
  type: string;
  payload: { authKey: string };
}) => {
  switch (action.type) {
    case RES_AUTH_SUCCESS:
      Cookies.set(TOKEN, action.payload.authKey, cookieOptions);
      break;
    case REQ_AUTH_LOGOUT:
      if (typeof window !== 'undefined') {
        Cookies.remove(TOKEN, cookieOptions);
        localStorage.removeItem('SP_STATE');
        Router.push('/');
      }

      break;
    // if a call to /user fails, we're not correctly authenticated
    // probably the cookie or token is missing or expired
    // if the app thinks the user is logged in force a logout to update UI
    case RES_USER_FAIL: {
      const state = store.getState();
      const isAuthed = selectIsAuthed(state);

      if (isAuthed) {
        store.dispatch(doLogout());
      }

      break;
    }
    default:
      break;
  }
  if (action.type && action.payload && action.payload.authKey) {
    Cookies.set(TOKEN, action.payload.authKey, cookieOptions);
  }
  return next(action);
};
