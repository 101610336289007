import { combineReducers } from 'redux';
import { LayoutReducer } from './layout';
import { UserReducer } from './user';
import ChannelCollectionReducer from './channel/ChannelCollection.redux';
import { ReleaseEntityReducer } from './releases/ReleaseCollection.redux';
import TrackCollectionReducer from './tracks/TrackCollection.redux';
import CurrentVideoReducer from './videos/CurrentVideoReducer';
import {
  VideosEntityReducer,
  VideoCollectionsEntityReducer,
} from './videos/VideosCollection.redux';
import { ChannelReducer } from './channel';
import { PlayerReducer } from './player';
import { reducer as APIErrorReducer } from './api/api.reducers';
import { CategoryEntityReducer } from './categories';

import { BundleEntityReducer, BundlePlansEntityReducer } from './subscribe';
import { ArticlesEntityReducer } from './articles';
import { ArticleCollectionsEntityReducer } from './articles/articles.reducers';

export default combineReducers({
  entities: combineReducers({
    categories: CategoryEntityReducer,
    videos: VideosEntityReducer,
    collections: VideoCollectionsEntityReducer,
    releases: ReleaseEntityReducer,
    bundles: BundleEntityReducer,
    bundlePlans: BundlePlansEntityReducer,
    tracks: TrackCollectionReducer,
    articles: ArticlesEntityReducer,
    articleCollections: ArticleCollectionsEntityReducer,
    // TODO Convert channel reducer to standard entity reducer
    channels: ChannelCollectionReducer,
  }),
  currentChannel: ChannelReducer,
  user: UserReducer,
  layout: LayoutReducer,
  currentVideo: CurrentVideoReducer,
  player: PlayerReducer,
  errors: APIErrorReducer,
});
