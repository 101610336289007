import { RES_VIDEO_LINK_SUCCESS } from '../channel';

const defaultState = { dash: '', hls: '', rtmp: '' };

export default function reducer(state: Object = defaultState, action: any) {
  switch (action.type) {
    case RES_VIDEO_LINK_SUCCESS: {
      const url = action.payload;
      return url;
    }
    default:
      return state;
  }
}
