import React from 'react';
import { detectChannelFromHostname, detectChannel } from '../app/detectChannel';

/*
 * Higher order component that passes `getInitialProps` through
 * to the child component
 */
export default function (Child) {
  return class Higher extends React.Component {
    static async getInitialProps(ctx) {
      let channelName;

      // if we're on the server we have a request object
      if (ctx.ctx && ctx.ctx.req) {
        // detect channel from hostname
        channelName = detectChannelFromHostname(
          ctx.ctx.req.headers['x-forwarded-host'] || ctx.ctx.req.headers.host
        );
      } else {
        // we can use window
        channelName = detectChannel();
      }
      // attach channelName to the app context for calling pages
      ctx.ctx.channelName = channelName;

      return Child.getInitialProps(ctx);
    }
    render() {
      return <Child {...this.props} />;
    }
  };
}
