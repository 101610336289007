/**
 * Redux middleware to catch API actions with relative URLS
 * and prefix the correct baseUrl from the environment config.
 */
import { RSAA } from 'redux-api-middleware';
import { BASE_URL, PROD } from '../api';

export default () => (next) => (action) => {
  const callApiAction = action[RSAA];

  if (callApiAction) {
    callApiAction.endpoint = BASE_URL + callApiAction.endpoint;
    if (!PROD) {
      console.log(callApiAction.endpoint);
    }
  }

  return next(action);
};
